// CONSENT SETTINGS

// TEST
console.log("content script working");

// BODY

// Cookie Content
const cookieContent = document.querySelector(".cookie-banner");

cookieContent.insertAdjacentHTML(
  "afterbegin",
  `
  <!-- Cookie Icon -->
  <div class="cookie-notice">
  <a class="cookie-icon" href="https://www.capetonianpoet.com/privacy#cookie-notice" aria-label="Cookie Notices"><svg xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 -960 960 960" width="25px" fill="#e8eaed"><path d="M480.27-80q-82.74 0-155.5-31.5Q252-143 197.5-197.5t-86-127.5Q80-398 80-480q0-83 31.5-155.5T197-763q54-55 126-86.5T475.56-881q19.44 0 38.44 1.5 19 1.5 38 5.5-6 40 6.5 78.5t39 68q26.5 29.5 66.5 45t89 9.5q-7 63 22 109t104 53q1 9 1 18v17q0 81.38-31.5 153.34Q817-250.71 763-196.62t-127 85.36Q563-80 480.27-80Zm-60.15-478Q446-558 464-576.12q18-18.12 18-44T463.88-664q-18.12-18-44-18T376-663.88q-18 18.12-18 44T376.12-576q18.12 18 44 18Zm-80 200Q366-358 384-376.12q18-18.12 18-44T383.88-464q-18.12-18-44-18T296-463.88q-18 18.12-18 44T296.12-376q18.12 18 44 18ZM600-320q17 0 28.5-11.5T640-360q0-17-11.5-28.5T600-400q-17 0-28.5 11.5T560-360q0 17 11.5 28.5T600-320ZM480-140q142 0 236-93.5T821-464q-54-20-87.5-59.5T692-614q-81-11-136.5-70T492-821q-74-3-138.5 24t-112 74Q194-676 167-612.5T140-480q0 142 99 241t241 99Zm1-345Z"/></svg></a>
  </div>
  <!-- Cookie Setting -->
  <div id="cookies">
		<div class="container">
			<div class="subcontainer">
				<div class="cookies">
					<p>This website uses cookies to ensure you get the best browsing experience. <a href="https://capetonianpoet.com/privacy#cookies-notice">Cookie Notice</a></p>
					<div class="consent">
            <button id="cookies-accept" class="cookies-btn">Accept</button>
            <button id="cookies-reject" class="cookies-btn">Reject</button>
          </div>
				</div>
			</div>
		</div>

	</div>
    `
);

// Cookie

setCookie = (cName, cValue, expDays) => {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
};

getCookie = (cName) => {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie);
  const cArr = cDecoded.split("; ");
  let value;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) value = val.substring(name.length);
  });

  return value;
};

function hideBanner() {
  document.querySelector("#cookies").style.display = "none";
}

if (localStorage.getItem("consentMode") === null) {
  document
    .getElementById("cookies-accept")
    .addEventListener("click", function () {
      setCookie("cookieDrop", true, 30);
      setConsent({
        necessary: true,
        analytics: true,
        preferences: true,
        marketing: true,
      });
      hideBanner();
      document.querySelector(".cookie-notice").style.display = "block";
    });
  document
    .getElementById("cookies-reject")
    .addEventListener("click", function () {
      setCookie("cookiePop", false, 14);
      setConsent({
        necessary: false,
        analytics: false,
        preferences: false,
        marketing: false,
      });
      hideBanner();
      document.querySelector(".cookie-notice").style.display = "block";
    });
  document.querySelector("#cookies").style.display = "block";
}

function setConsent(consent) {
  const consentMode = {
    functionality_storage: consent.necessary ? "granted" : "denied",
    security_storage: consent.necessary ? "granted" : "denied",
    ad_storage: consent.marketing ? "granted" : "denied",
    analytics_storage: consent.analytics ? "granted" : "denied",
    personalization_storage: consent.preferences ? "granted" : "denied",
    // Additional Parameters
    ad_user_data: consent.marketing ? "granted" : "denied",
    ad_personalization: consent.marketing ? "granted" : "denied",
  };
  //   gtag("consent", "update", consentMode);
  localStorage.setItem("consentMode", JSON.stringify(consentMode));
}

// Cookie Notice 

if (localStorage.getItem("consentMode")) {
  document.querySelector(".cookie-notice").style.display = "block";
}